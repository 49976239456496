<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      
      <!-- List of organizations -->
      <v-col cols="12" md="7">

        <SSRTable ref="list-orgs"
          :data-url="urlGetOrgs" :schema="schemaListOrgs"
          selectable @select-row="onOrgSelectionChanged">

          <!-- <div slot="actions" slot-scope="{row}">
            <v-btn rounded small color="primary"
              @click="showListOfAdmins">
              <v-icon left>mdi-credit-card-outline</v-icon>
            </v-btn>
          </div> -->
        </SSRTable>

      </v-col>

      <!-- List of admins of the selected organization -->
      <v-col cols="12" md="5" transition="scroll-x-transition">

        <v-card outlined elevation="0" v-show="expansion.admins.show">
          <v-card-title>
            Administrators of {{ selectedOrg.name }}
          </v-card-title>

          <!-- Form - assign a user as a new admin -->
          <v-card-actions>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12" class="d-flex align-center">
                    <v-autocomplete dense outlined label="Add an Admin" hide-details
                      clearable v-model="expansion.admins.selectedUser"
                      :items="users" item-text="display_name" return-object
                      :filter="filterUsers">

                      <v-list-item slot="prepend-item">
                        <v-btn outlined small color="warning" @click="dialog.createUser.show = true">
                          <v-icon left>mdi-account-plus</v-icon>Create a New User
                        </v-btn>
                      </v-list-item>

                      <template v-slot:item="data">
                        {{ data.item['display_name'] }} ({{ data.item['username'] }})
                      </template>
                    </v-autocomplete>

                    <v-btn fab small elevation="0" color="indigo" class="ml-2" @click="assignAdmin">
                      <v-icon>mdi-account-check</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
              
            </v-form>
          </v-card-actions>

          <!-- List of admins itself -->
          <v-card-text>
            <SSRTable ref="list-admins"
              :data-url="urlGetAdmins" :schema="schemaListAdmins">

              <div slot="actions" slot-scope="{ item }">
                <v-btn fab x-small elevation="0" color="error" @click="revokeAdmin(item)">
                  <v-icon>mdi-account-remove</v-icon>
                </v-btn>
              </div>
            </SSRTable>
          </v-card-text>
        </v-card>

      </v-col>

    </v-row>

    <!-- Dialog - Set Admins -->
    <v-dialog v-model="dialog.createUser.show" width="unset" max-width="40%">
      <v-card>
        <v-card-title>Create a New User</v-card-title>

        <v-card-text>
          <v-form :disabled="dialog.createUser.isLoading">
            <v-text-field v-model="dialog.createUser.model.displayName" label="Display Name"
              :error-messages="validation.firstError('dialog.createUser.model.displayName')">
            </v-text-field>
            <v-text-field v-model="dialog.createUser.model.email" type="email" label="Email"
              :error-messages="validation.firstError('dialog.createUser.model.email')">
            </v-text-field>
            <div class="d-flex justify-space-between">
              <v-text-field v-model="dialog.createUser.model.username" label="Username"
                :disabled="dialog.createUser.model.usernameUseEmail"
                :error-messages="validation.firstError('dialog.createUser.model.username')">
              </v-text-field>
              <v-checkbox label="Same as Email" v-model="dialog.createUser.model.usernameUseEmail"
                @change="onUsernameUseEmailChanged">
              </v-checkbox>
            </div>
            <v-text-field v-model="dialog.createUser.model.password" type="password" label="Password"
              :error-messages="validation.firstError('dialog.createUser.model.password')">
            </v-text-field>
            <v-text-field v-model="dialog.createUser.model.confirm" type="password" label="Confirm Password"
              :error-messages="validation.firstError('dialog.createUser.model.confirm')">
            </v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn depressed color="warning" @click="createUser" :disabled="dialog.createUser.isLoading">
            <v-icon left>mdi-account-plus</v-icon>Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable';
import ScopeSelector from '@/components/ScopeSelector';

import SimpleVueValidation from 'simple-vue-validator';
import axios from 'axios';

const Validator = SimpleVueValidation.Validator;

export default {
  components: {
    SSRTable,
    ScopeSelector
  },

  mounted() {

  },

  methods: {
    onOrgSelectionChanged(org, isSelected) {
      if (!isSelected) return;
      console.log(`[DEBUG] onOrgSelectionChanged`, org);
      this.selectedOrg = org;

      // Show expansion of admin list.
      this.fetchUsers();
      this.expansion.admins.selectedUserId = null;
      this.expansion.admins.show = true;
    },

    fetchUsers() {
      axios.get(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/users?limit=-1`)
      .then(response => {
        if (!response.data['users'])
          throw new Error(`Cannot fetch users!`);
        this.users = response.data['users'];
      })
      .catch(err => {
        this.$notify({
          title: err.message,
          type: 'error'
        });
      });
    },

    filterUsers(item, queryText) {
      let keyword = queryText.toLowerCase();
      let userDisplayName = item['display_name'].toLowerCase();
      return userDisplayName.indexOf(keyword) >= 0
        || item['username'].indexOf(keyword) >= 0
        || item['email'].indexOf(keyword) >= 0;
    },

    onUsernameUseEmailChanged(isChecked) {
      if (!isChecked) return;
      this.$validate('dialog.createUser.model.email')
      .then(valid => {
        if (!valid) {
          this.dialog.createUser.model.usernameUseEmail = false;//Uncheck it.
          return;
        }
        this.dialog.createUser.model.username = this.dialog.createUser.model.email;
      });
    },

    assignAdmin() {
      console.log(`Will assign user ${this.expansion.admins.selectedUserId} to org ${this.selectedOrg.alias}`);
      axios.post(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/org-admins`, {
        orgId: this.selectedOrg['id'],
        userId: this.expansion.admins.selectedUser['id']
      })
      .then(response => {
        this.$notify({
          title: `Successfully assigned ${this.expansion.admins.selectedUser['display_name']} to ${this.selectedOrg['name']}.`,
          type: 'success'
        });
        this.$refs['list-admins'].loadData();
      })
      .catch(err => {
        this.$notify({
          title: `Failed to assign ${this.expansion.admins.selectedUser['display_name']} to ${this.selectedOrg['name']}.`,
          type: 'error'
        });
      });
    },
    revokeAdmin(user) {
      if (!confirm(`Sure you want to remove this admin from this organization?`))
        return;
      
      axios.delete(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/org-admins`, {
        data: { orgId: this.selectedOrg['id'], userId: user['id'] }
      })
      .then(response => {
        this.$notify({
          title: `Successfully revoked ${user['display_name']} from ${this.selectedOrg['name']}.`
        });
      })
      .catch(err => {
        this.$notify({
          title: `Cannot remove ${user['display_name']} from ${this.selectedOrg['name']}.`,
          type: 'error'
        });
      })
      .finally(() => {
        this.$refs['list-admins'].loadData();
      });
    },
    createUser() {
      this.$validate()
      .then(valid => {
        if (!valid) return;

        this.dialog.createUser.isLoading = true;
        axios.post(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/users`, {
          displayName: this.dialog.createUser.model.displayName,
          email: this.dialog.createUser.model.email,
          username: this.dialog.createUser.model.username,
          password: this.dialog.createUser.model.password
        })
        .then(response => {
          this.$notify({
            title: `Successfully created user ${this.dialog.createUser.model.displayName} (${this.dialog.createUser.model.username}).`,
            type: 'success'
          });
          this.fetchUsers();//NOTE: Renew list of users.
        })
        .catch(err => {
          this.$notify({
            title: `Failed to create user.`,
            type: 'error'
          });
        })
        .finally(() => {
          this.dialog.createUser.show = false;
          this.dialog.createUser.isLoading = false;
        });
      });
      
    }

  },

  data() {
    return {
      
      selectedOrg: { name: '' },
      users: [ ],

      expansion: {
        admins: {
          show: false,
          selectedUser: null
        }
      },

      dialog: {
        createUser: {
          show: false,
          isLoading: false,
          model: {
            displayName: '',
            email: '',
            username: '',
            password: '',
            confirm: '',

            usernameUseEmail: false
          }
        }
      }
    };
  },

  computed: {

    urlGetOrgs() {
      return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orgs`;
    },
    urlGetAdmins() {
      if (this.selectedOrg['id'])
        return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/org-admins?orgId=${this.selectedOrg['id']}`;
      return undefined;
    },

    schemaListOrgs() {
      return [
        { value: 'name', text: 'Organization', attrs: { } },
        { value: 'alias', text: 'Alias', attrs: { } },
        { value: 'email', text: 'Email', attrs: { } },
        { value: 'phone', text: 'Phone', attrs: {  } },
        { value: 'address', text: 'Address', attrs: { } },
        { value: 'tax_number', text: 'Tax Number', attrs: {  } },
        { value: 'actions', text: '', attrs: {  } }
      ];
    },
    schemaListAdmins() {
      return [
        { value: 'display_name', text: 'Display Name', attrs: { } },
        { value: 'username', text: 'Username', attrs: { } },
        { value: 'email', text: 'Email', attrs: { } },
        { value: 'actions', text: 'Revoke', attrs: { } }
      ];
    }

  },

  validators: {
    'dialog.createUser.model.displayName': v => {
      return Validator.value(v).required().minLength(6);
    },
    'dialog.createUser.model.email': v => {
      return Validator.value(v).required().minLength(6).email();
    },
    'dialog.createUser.model.username': v => {
      return Validator.value(v).required().minLength(6);
    },
    'dialog.createUser.model.password': v => {
      return Validator.value(v).required().minLength(8);
    },
    'dialog.createUser.model.confirm, dialog.createUser.model.password': (confirm, pw) => {
      return Validator.value(confirm).required().match(pw);
    }

  }
}
</script>
